import { useEffect } from 'react';
import { useAccount, useChainId, useWalletClient } from 'wagmi';
import { useDisconnect } from 'wagmi';
import {
    setAddress,
    setConnected,
    setChainId,
    setConnectError,
    setWeb3,
    setIsPolis,
} from '../state/user';
import { useAppDispatch } from 'state/hooks';
import Web3 from 'web3';
import { getSupportChainData } from 'common-helpers/utils';
import Modal from 'utils/components/modal';
import storage from 'utils/storage';
import { AUTH_PARAMS } from 'constants/storage';

function initWeb3(provider: any) {
    const web3: any = new Web3(provider);
    web3.eth.extend({
        methods: [
            {
                name: 'chainId',
                call: 'eth_chainId',
                outputFormatter: web3.utils.hexToNumber,
            },
        ],
    });
    return web3;
}

export default function useWalletConnect() {
    const dispatch = useAppDispatch();
    const chainId = useChainId();

    const { address, isConnecting, isDisconnected, connector, isConnected } =
        useAccount();
    const { data: walletClient, isError, isLoading } = useWalletClient();
    const { disconnect } = useDisconnect();
    function resetApp() {
        dispatch(setAddress(''));
        dispatch(setConnected(false));
        dispatch(setWeb3(undefined));
    }

    async function detectIsCorrectNetwrok() {
        if (!window.ethereum) return false;
        const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
            params: [],
        });
        const cid = Number(currentChainId);
        const chainData = getSupportChainData(cid);
        if (!chainData) {
            // hook will be called many times, filter muti alert
            if (!(window as any).alertChangeNetwork) {
                (window as any).alertChangeNetwork = Modal({
                    type: 'error',
                    text: 'Please switch to Ethereum Mainnet or Andromeda network',
                    buttonText: 'OK',
                    onClose: async () => {
                        (window as any).alertChangeNetwork = null;
                        resetApp();
                        await disconnect();
                    },
                });
            }
            return false;
        } else if ((window as any).alertChangeNetwork) {
            (window as any).alertChangeNetwork();
            (window as any).alertChangeNetwork = null;
        }

        return true;
    }
    useEffect(() => {
        if (address && walletClient && !isDisconnected) {
            dispatch(setAddress(address));
            dispatch(setConnected(true));
            dispatch(setWeb3(initWeb3(walletClient)));
            dispatch(setIsPolis(false));
        } else {
            const authParams = storage.get(AUTH_PARAMS);

            if (authParams) {
                const now = new Date().getTime();
                const expireAt = authParams.expireAt;
                if (expireAt > now) {
                    return;
                }
            }

            dispatch(setAddress(''));
            dispatch(setConnected(false));
            dispatch(setWeb3(undefined));
        }
    }, [dispatch, address, isDisconnected, walletClient]);

    useEffect(() => {
        const authParams = storage.get(AUTH_PARAMS);

        if (authParams) {
            const now = new Date().getTime();
            const expireAt = authParams.expireAt;
            if (expireAt > now) {
                return;
            }
        }
        if (chainId) {
            dispatch(setChainId(chainId));
        }
    }, [chainId]);

    useEffect(() => {
        if (isConnected) {
            detectIsCorrectNetwrok();
        }

        if (window.ethereum) {
            window.ethereum.on('chainChanged', async (chainId: any) => {
                detectIsCorrectNetwrok();
            });
        }
    }, [isConnected]);

    return null;
}
